import { useState, useCallback } from 'react';
import { solarData } from '../components/Quote/SolarData';
import { FinanceData } from '../components/Quote/FinanceData';

const useHardware = () => {
    const initialHardwareState = {
        solutions: [],
        module: solarData.modules.options[0],
        inverter: solarData.inverters.options[0],
        systemSize: 0,
        offset: 100,
        kWh: 0,
        unit: '',
        battery: solarData.batteries.options[0],
        batteryQuantity: 0,
        backupHours: 0,
        recommendedBattQuantity: 0,
        batteryState: true,
        powerwallCost: 0,
        additionalPowerwallCost: 8500,
        capacity: 0,
        services: solarData.services.options[0],
        serviceQuantity: 0,
        solarPPW: 0,
        solarCost: 0,
        batteryCost: 0,
        serviceCost: 0,
        totalCost: 0,
        taxCredit: 0,
        totalAfterTaxCredit: 0,
        downPayment: 0,
        financedTotal: 0,
        financedMonthlyPaymentPreTaxCredit: 0,
        financedMonthlyPaymentAfterTaxCredit: 0,
        selectedLender: Object.keys(FinanceData.Lenders[0])[0], 
        selectedProduct: FinanceData.Lenders[0][Object.keys(FinanceData.Lenders[0])[0]][0],
        paymentType: '',
        satelliteImage: null,
        designInfo: null,
        designImage: null,
        designText: 'Loading Home...',
        annualProduction: 0
    }

    const [hardware, setHardware] = useState(initialHardwareState);

    const updateHardware = useCallback((field, value) => {
        setHardware((prevState) => ({
          ...prevState,
          [field]: typeof value === 'function' ? value(prevState[field]) : value,
        }));
    }, []);

    const updateLender = useCallback((lender) => {
        const newSelectedLender = FinanceData.Lenders.find((l) => l[lender]);
        const newProducts = newSelectedLender ? newSelectedLender[lender] : [];
        const newSelectedProduct = newProducts[0] || {};
    
        setHardware((prevHardware) => ({
          ...prevHardware,
          selectedLender: lender,
          selectedProduct: newSelectedProduct,
        }));
    }, []);

    // Function to update selected product
  const updateProduct = useCallback((product) => {
    const lenderProducts = FinanceData.Lenders.find((l) => l[hardware.selectedLender])[hardware.selectedLender];
    const newSelectedProduct = lenderProducts.find((productData) => productData.product === product);

    setHardware((prevHardware) => ({
      ...prevHardware,
      selectedProduct: newSelectedProduct,
    }));
  }, [hardware.selectedLender]);

  // Function to add or remove a value from the solutions array
  const updateSolutions = useCallback((value) => {
    setHardware((prevHardware) => {
      const isValueInSolutions = prevHardware.solutions.includes(value);

      return {
        ...prevHardware,
        solutions: isValueInSolutions
          ? prevHardware.solutions.filter((item) => item !== value) // Remove value
          : [...prevHardware.solutions, value], // Add value
      };
    });
  }, []);

  // Function to reset hardware to its initial state
  const resetHardwareState = useCallback(() => {
    setHardware(initialHardwareState); // Reset hardware state to its initial values
  }, []);

  return { hardware, updateHardware, updateLender, updateProduct, updateSolutions, resetHardwareState };
}

export default useHardware;
import React, { useState, useEffect, useRef } from 'react';

const OTPInput = ({ length = 6, setOTP }) => {
    const [otpArray, setOtpArray] = useState(new Array(length).fill(''));
    const inputsRef = useRef([]);

    const isInputValueValid = (value) => /^[0-9]*$/.test(value);
  
    const handleChange = (event, index) => {
      const value = event.target.value;
  
      // If the input contains the entire OTP, split and populate the fields
      if (value.length === length && isInputValueValid(value)) {
        const newOtp = value.split('').slice(0, length);
        setOtpArray(newOtp);
        setOTP(newOtp.join(''));
        inputsRef.current[length - 1]?.focus();
        return;
      }
  
      // Handle single character input for regular typing
      if (isInputValueValid(value)) {
        const newOtp = [...otpArray];
        newOtp[index] = value.charAt(0); // Only use the first character
        setOtpArray(newOtp);
        setOTP(newOtp.join(''));
  
        // Move to the next input field if a valid value is entered
        if (value && index < length - 1) {
          inputsRef.current[index + 1]?.focus();
        }
      }
    };
  
    const handleKeyDown = (event, index) => {
      if (event.key === 'Backspace') {
        event.preventDefault();
        const newOtp = [...otpArray];
        if (otpArray[index] === '' && index > 0) {
          newOtp[index - 1] = '';
          inputsRef.current[index - 1]?.focus();
        } else {
          newOtp[index] = '';
        }
        setOtpArray(newOtp);
        setOTP(newOtp.join(''));
      }
    };
  
    const handlePaste = (event) => {
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData('text')
        .replace(/[^0-9]/g, ''); // Sanitize pasted data
      if (pastedData.length === length) {
        const newOtp = pastedData.split('');
        setOtpArray(newOtp);
        setOTP(newOtp.join(''));
        inputsRef.current[length - 1]?.focus();
      }
    };

    // Automatically fill OTP using Web OTP API
    useEffect(() => {
      const fetchOTP = async () => {
          try {
              if ('OTPCredential' in window) {
                  const otpCredential = await navigator.credentials.get({
                      otp: { transport: ['sms'] },
                      signal: new AbortController().signal, // Optional timeout
                  });

                  if (otpCredential && otpCredential.code) {
                      const code = otpCredential.code.slice(0, length);
                      const newOtp = code.split('');
                      setOtpArray(newOtp);
                      setOTP(newOtp.join(''));
                  }
              }
          } catch (error) {
              console.error('Error fetching OTP:', error);
          }
      };

      fetchOTP();
  }, [length, setOTP]);
  
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }} onPaste={handlePaste}>
        {otpArray.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (inputsRef.current[index] = el)}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            value={digit}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            style={{
              width: 'calc(100% / 8)',
              height: '40px',
              maxWidth: '40px',
              maxHeight: '40px',
              textAlign: 'center',
              fontSize: '20px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        ))}
      </div>
    );
  };
  
  export default OTPInput;
  
  

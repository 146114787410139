import { useState, useEffect, useCallback } from 'react';

const useAutoDesigner = ({
    hardware,
    updateHardware,
    responseState,
    formState,
    setDesignLocked,
}) => {
    const [designStatus, setDesignStatus] = useState('pending');
    const [submitted, setSubmitted] = useState(false);
    const [designInfo, setDesignInfo] = useState(null);
    const [isPolling, setIsPolling] = useState(true);

    // Function to start the design automation process
    const startAutomation = async () => {
        setDesignLocked(true);

        let inverterName = hardware.inverter.name;
        let battery = null;

        // Set battery name and quantity if present
        if (hardware.batteryQuantity > 0) {
            battery = {
                quantity: hardware.batteryQuantity,
            };

            if (hardware.battery.name === "Tesla Powerwall 3") {
                battery.name = hardware.inverter.name === "Tesla Inverter"
                    ? "Tesla Powerwall 3 (DC Coupled)"
                    : "Tesla Powerwall 3 (AC Coupled)";
                inverterName = hardware.inverter.name === "Tesla Inverter" ? "Tesla Powerwall 3" : inverterName;
            } else {
                battery.name = hardware.battery.name;
            }
        }
        
        setSubmitted(true);

        const annualProduction = responseState.solarProductionMonthly * 12;

        const body = {
            address: formState.address,
            customerName: formState.name,
            inverter: inverterName,
            battery: battery,
            targetOffset: hardware.offset.toFixed(1),
            hardware: hardware
        };

        if (annualProduction > 30000) {
            body.targetEnergy = "30000";
        }

        try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/estimate/run-automation', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        const result = await response.json();
        // console.log(result);

        setIsPolling(true);
        } catch (error) {
        console.error('Error:', error);
        }
    };

    // Function to create proposals
    const createProposals = useCallback(async () => {
        const { projectId } = responseState.projectId;
        const adders = [];

        // Map hardware items to the adder names and quantities
        if (hardware.battery && hardware.batteryQuantity > 0) {
            const adderName = hardware.battery.name === 'Tesla Powerwall 3'
                ? 'Tesla Powerwall 3 (Storage Only)'
                : hardware.battery.name === 'Enphase 5P'
                ? 'Enphase 5P (Solo)'
                : hardware.battery.name;
            adders.push({ name: adderName, quantity: hardware.batteryQuantity });
        }

        if (hardware.inverter) {
            // Only add inverters if it's not the Enphase 5P, since it's a battery.
            const adderName = hardware.inverter.name;
            adders.push({ name: adderName, quantity: 1 }); // Assuming 1 inverter per design
        }

        if (hardware.evCharger && hardware.evChargerQuantity > 0) {
            const adderName = hardware.evCharger.name === 'Wallbox Pulsar Plus 2'
                ? 'Wallbox EV Charger (Pulsar Plus Level 2 - 40A) - FL Only'
                : hardware.evCharger.name;
            adders.push({ name: adderName, quantity: hardware.evChargerQuantity });
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate/createProposals`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectId, adders }),
            });
            const result = await response.json();
            // console.log('Proposal creation result:', result);
        } catch (error) {
            console.error('Error creating proposals:', error);
        }
    }, [hardware.battery, hardware.batteryQuantity, hardware.evCharger, hardware.evChargerQuantity, hardware.inverter, responseState.projectId]);

    // Function to update proposals
    const createDesign = async (projectId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate/createDesign?projectId=${projectId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            // console.log('Create Design result:', result);
        } catch (error) {
            console.error('Error creating design:', error);
        }
    };

    // Function to update proposals
    const updateProposals = async (projectId, downPayment) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate/updateProposals`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectId, downPayment }),
            });
            const result = await response.json();
            // console.log('Proposal update result:', result);
        } catch (error) {
            console.error('Error updating proposals:', error);
        }
    };

    // Function to select a proposal
    const selectProposal = async (projectId, proposalId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate/selectProposal`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectId, proposalId }),
            });
            const result = await response.json();
            // console.log('Proposal selection result:', result);
        } catch (error) {
            console.error('Error selecting proposal:', error);
        }
    };

    // Function to fetch the design status
    const fetchDesignStatus = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate/getCustomerDesignStatus/${formState.address}`);
            const result = await response.json();

            // Update design info and status
            updateHardware('designInfo', result);

            switch (result.designStatus) {
                case 'initial':
                    setDesignStatus('initial');
                    updateHardware('designImage', result.initial);
                    updateHardware('designText', "Determining Roof Dimensions...");
                    break;
                case 'roof':
                    setDesignStatus('roof');
                    updateHardware('designImage', result.roof);
                    updateHardware('designText', "Designing Your System...");
                    break;
                case 'design':
                    setDesignStatus('design');
                    updateHardware('designImage', result.design);
                    updateHardware('designText', "Finalizing System...");
                    break;
                case 'lidar':
                    setDesignStatus('lidar');
                    updateHardware('designImage', result.lidar);
                    updateHardware('designText', "Creating Irradiance Map...");
                    break;
                case 'irradiance':
                    setDesignStatus('irradiance');
                    updateHardware('designImage', result.irradiance);
                    updateHardware('designText', "Finalizing System...");
                    break;
                
                case 'completed':
                    setDesignStatus('completed');
                    updateHardware('systemSize', result.systemSize);
                    updateHardware('offset', parseInt(result.energyOffset.replace('%', ''), 10));
                    updateHardware('designImage', result.screenshot3d);
                    setDesignInfo(result);
                    const sumOfProduction = result.monthlyProduction
                        .map(value => parseFloat(value))  // Convert each string to a decimal number
                        .reduce((acc, curr) => acc + curr, 0);
                    updateHardware('annualProduction', sumOfProduction);

                    // Call createProposals after setting the design status to 'completed'
                    // createProposals();
                    break;
                case 'incompatible':
                    setDesignStatus('incompatible');
                    updateHardware('designText', "We were not able to get up to date imaging for your home.");
                    break;
                case 'failed':
                    setDesignStatus('failed');
                    updateHardware('designText', "Skynet overload, retrying...");
                    break;
                case 'failedFinal':
                    setDesignStatus('failedFinal');
                    updateHardware('designText', "BEEP BOOP ERROR");
                    break;
                default:
                    setDesignStatus('loading');
                    updateHardware('designText', "Loading Home...");
            }
        } catch (error) {
        console.error('Error fetching design status:', error);
        }
    }, [formState.address, updateHardware]);

    // Function to reset design state
    const resetDesignState = useCallback(() => {
        setDesignStatus('pending');  
        setSubmitted(false);
        setDesignInfo(null);
        setIsPolling(false);
        updateHardware('designImage', hardware.satelliteImage);
        updateHardware('designText', "Loading Home...");
        setDesignLocked(false);
    }, [updateHardware, setDesignLocked, hardware.satelliteImage]);

    // Poll the design status every 5 seconds if design is not completed
    useEffect(() => {
        let intervalId;
        let timeoutId;

        // console.log("design status ", designStatus);

        if (submitted && isPolling && designStatus !== 'completed' && designStatus !== 'incompatible' && designStatus !== 'failedFinal' && designStatus !== 'errored') {
            // Add a delay of 10 seconds before starting the interval
            timeoutId = setTimeout(() => {
                intervalId = setInterval(() => {
                    fetchDesignStatus();
                }, 5000); // Poll every 5 seconds
            }, 10000); // Delay the first fetch by 10 seconds
        }

        // Cleanup function: clear the timeout and interval
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [designStatus, submitted, isPolling, fetchDesignStatus]);

    const stopPolling = () => {
        setIsPolling(false);
    };

    return {
        designStatus,
        setDesignStatus,
        designInfo,
        startAutomation,
        updateProposals,
        selectProposal,
        createDesign,
        stopPolling,
        resetDesignState
    };
};

export default useAutoDesigner;

import missionSolar from '../../images/MissionSolar.png';
import USFlag from '../../images/USFlag.png';
import Powerwall3 from '../../images/Powerwall3.png';
import Enphase5P from '../../images/Enphase5P.webp';
import IQ8PLUS from '../../images/IQ8PLUS.png';
import TeslaInverter from '../../images/TeslaInverter2.jpg';
import Wallbox from '../../images/WallboxCharger.jpg';

export const solarData = {
    modules: {
      title: "Modules",
      options: [
        {
          name: "Mission Solar",
          image: missionSolar,
          watts: 410,
          ppw: 2.05,
          ppwNE: 3,
          column1: {
            "Rated Power": "410W",
            // "Efficiency": "21%"
          },
          column2: {
            "Warranty": "25 years"
          },
          "Spec Sheet": "/files/MissionSolar.pdf",
          manufacturerLocationImage: USFlag,
        },
      ],
    },
    inverters: {
      title: "Inverters",
      options: [
        {
          name: "Tesla Inverter",
          image: TeslaInverter,
          ppw: 0,
          column1: {
            "Type": "String Inverter",
          },
          column2: {
            "Warranty": "10 years",
          },
          "Spec Sheet": "/files/TeslaInverterSpecs.pdf",
          manufacturerLocationImage: USFlag,
        },
        {
          name: "Enphase IQ8+",
          image: IQ8PLUS,
          ppw: .15,
          column1: {
            "Type": "Microinverter",
          },
          column2: {
            "Warranty": "25 years",
          },
          "Spec Sheet": "/files/IQ8SP.pdf",
          manufacturerLocationImage: USFlag,
        },
      ],
    },
    batteries: {
      title: "Batteries",
      options: [
        {
          name: "Tesla Powerwall 3",
          image: Powerwall3,
          firstUnit: 14000,
          additionalUnits: 8500,
          inverterPrice: 8500,
          capacity: 13.5,
          column1: {
            "Capacity": "13.5 kWh",
          },
          column2: {
            "Warranty": "10 years",
          },
          "Spec Sheet": "/files/Powerwall3Specs.pdf",
          manufacturerLocationImage: USFlag,
        },
        {
          name: "Enphase 5P",
          image: Enphase5P,
          firstUnit: 8000,
          additionalUnits: 5000,
          capacity: 5,
          column1: {
            "Capacity": "5 kWh",
          },
          column2: {
            
            "Warranty": "15 years",
          },
          "Spec Sheet": "/files/Enphase5P.pdf",
          manufacturerLocationImage: USFlag,
        },
      ],
    },
    services: {
      title: "EV Chargers",
      options: [
        {
          name: "Wallbox Pulsar Plus 2",
          image: Wallbox,
          price: 1000,
          column1: {
            "Max Current": "40Amps | 9.6kW",
          },
          column2: {
            "Voltage": "240/208V (Level 2)",
          },
          "Spec Sheet": "/files/WallboxSpecs.pdf",
          manufacturerLocationImage: USFlag,
        }
      ]
    }
  };
  
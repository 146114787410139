import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { TextField, ToggleButton, ToggleButtonGroup, Slider, Box, Typography } from '@mui/material';
import { NextButton } from './QuoteElements';
import { solarData } from './SolarData';
import { StyledTypography } from './Design';

// Styled components
const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 50px; */
  margin-top: 50px;
`;

const TopContainer = styled.div`
  width: 90%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Value = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #555;
`;

const Form = styled.form`
  width: 90%;
  max-width: 500px;
  height: auto;
`;

const StyledTextField = styled(TextField)`
/* padding-top: 10px; */
  .MuiOutlinedInput-root {
    & fieldset {
      border-color: white; /* Default border color */
    }
    &:hover fieldset {
      border-color: white; /* Border color on hover */
    }
    &.Mui-focused fieldset {
      border-color: white; /* Border color when focused */
    }
  }

  .MuiInputLabel-outlined {
    color: black; /* Label color */
  }

  .MuiOutlinedInput-input {
    color: black; /* Input text color */
  }
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 45px;
`




const SliderContainer = styled(Box)`
  width: 95%;
  /* height: 250px; */
  /* margin: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
`;

const SliderHeader = styled(Typography)`
  font-size: 0.9rem !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
`;

const CustomSlider = styled(Slider)`
  width: 100%;
  margin-top: 10px;

  .MuiSlider-mark {
    background-color: white;
    width: 25px;
    height: 25px;
    border: 4px solid #1976d2;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);

    &:hover {
      width: 30px;
      height: 30px; 
    }
  }

  .MuiSlider-markActive {
    background-color: #1976d2;
    opacity: 1;
  }

  .MuiSlider-thumb {
    background-color: #1976d2; 
    width: 22px;
    height: 22px;
  }

  .MuiSlider-track {
    background-color: #1976d2;
  }

  .MuiSlider-rail {
    background-color: #1976d2; 
    opacity: 0.3; 
  }

  .MuiSlider-markLabel {
    font-size: 0.9rem; 
    font-weight: bold;
  }


`;

const CustomInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #ccc;
  padding-right: 5px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SolarProductionOffsetSlider = ({ estimateSize, hardware, updateHardware, solarProductionMonthly }) => {
  const [customSelected, setCustomSelected] = useState(false);
  const [customValue, setCustomValue] = useState('');
  const [unit, setUnit] = useState('offset');
  const [selectedRadio, setSelectedRadio] = useState(1);
  const [calculatedValues, setCalculatedValues] = useState([]);
  const [maxMessage, setMaxMessage] = useState('');

  const { maxOffset, maxSystemSize } = useMemo(() => {
    const annualProduction = solarProductionMonthly * 12;
    const maxProduction = 30000;
    const maxSystemSize = Math.floor((estimateSize * maxProduction) / annualProduction);
    const maxOffset = (maxSystemSize / estimateSize) * 100;
    return { maxOffset, maxSystemSize };
  }, [solarProductionMonthly, estimateSize]);

  const fixedOffsetValues = useMemo(() => {
    let values;
    if (maxOffset >= 115) {
      values = [90, 100, 115];
    } else {
      const maxOffsetRounded = Math.floor(maxOffset);
      const thirdOffset = maxOffsetRounded;
      const secondOffset = Math.max(maxOffsetRounded - 5, 0);
      const firstOffset = Math.max(secondOffset - 5, 0);
      values = [firstOffset, secondOffset, thirdOffset];
    }
    return values;
  }, [maxOffset]);

  const calculateSystemSize = useCallback(
    (value, unit) => {
      const moduleOption = solarData.modules.options.find(
        (option) => option.name === hardware.module.name
      );
      const wattsPerModule = moduleOption ? moduleOption.watts : 0;
      let newSystemSize;

      if (unit === 'kW') {
        newSystemSize = Math.ceil((value * 1000) / wattsPerModule) * wattsPerModule;
      } else {
        newSystemSize = Math.ceil(((value / 100) * estimateSize) / wattsPerModule) * wattsPerModule;
      }

      // console.log("new sys size", newSystemSize);
      // console.log("max sys size", maxSystemSize);
      // Ensure newSystemSize does not exceed maxSystemSize
      if (newSystemSize > maxSystemSize) {
        newSystemSize = maxSystemSize;
        setMaxMessage(`* Max System Size is ${(maxSystemSize / 1000).toFixed(2)} kW`)
      }

      // console.log("new sys size", newSystemSize);
      const newOffset = (newSystemSize / estimateSize) * 100;

      // console.log("new offset", newOffset);

      return { newSystemSize, newOffset };
    },
    [estimateSize, hardware.module.name, maxSystemSize]
  );

  useEffect(() => {
    const newValues = fixedOffsetValues.map((offset) => calculateSystemSize(offset, 'offset'));
    // console.log("update sys size and offset");
    setCalculatedValues(newValues);
    const defaultValues = newValues[1];
    updateHardware('systemSize', defaultValues.newSystemSize);
    updateHardware('offset', defaultValues.newOffset);
  }, [calculateSystemSize, updateHardware, fixedOffsetValues]);

  const handleRadioChange = useCallback(
    (event, newValue) => {
      setSelectedRadio(newValue);

      if (newValue === 3) {
        setCustomSelected(true);
      } else {
        setCustomSelected(false);
        setCustomValue('');

        const { newSystemSize, newOffset } = calculateSystemSize(
          fixedOffsetValues[newValue],
          'offset'
        );
        updateHardware('systemSize', newSystemSize);
        updateHardware('offset', newOffset);
      }
    },
    [calculateSystemSize, fixedOffsetValues, updateHardware]
  );

  const handleCustomChange = useCallback((e) => {
    setCustomValue(e.target.value);
  }, []);

  const handleUnitChange = useCallback((event, newUnit) => {
    if (newUnit !== null) {
      setUnit(newUnit);
    }
  }, []);

  const handleConfirmCustomValue = useCallback(() => {
    let inputValue = parseFloat(customValue);

    if (isNaN(inputValue) || inputValue <= 0) {
      // Handle invalid input
      return;
    }

    if (unit === 'offset') {
      if (inputValue > maxOffset) {
        inputValue = maxOffset;
      }
    } else if (unit === 'kW') {
      const inputSystemSize = inputValue * 1000;
      if (inputSystemSize > maxSystemSize) {
        inputValue = maxSystemSize / 1000; // Convert back to kW
      }
    }

    const { newSystemSize, newOffset } = calculateSystemSize(inputValue, unit);
    updateHardware('systemSize', newSystemSize);
    updateHardware('offset', newOffset);
  }, [calculateSystemSize, customValue, unit, updateHardware, maxOffset, maxSystemSize]);

  const marks = useMemo(
    () => [
      ...calculatedValues.map((value, index) => ({
        value: index,
        label: `${value.newOffset.toFixed(0)}%`,
      })),
      { value: 3, label: 'Custom' },
    ],
    [calculatedValues]
  );

  function valuetext(value) {
    return `${value}`;
  }

  const valueLabelFormat = useCallback(
    (value) => {
      const mark = marks.find((mark) => mark.value === value);
      return mark ? mark.label : value;
    },
    [marks]
  );

  return (
    <ComponentWrapper>
      <TopContainer>
        <Column>
          <Value>{(Math.round(hardware.systemSize) / 1000).toFixed(2)} kW</Value>
          <Title>System Size</Title>
        </Column>
        <Column>
          <Value>{Math.round(hardware.offset)}%</Value>
          <Title>Offset</Title>
        </Column>
      </TopContainer>
      <Form>
        <SliderContainer>
          <SliderHeader align="left" variant="body1">
            Choose Your Energy Offset %
          </SliderHeader>
          <CustomSlider
            value={selectedRadio}
            onChange={handleRadioChange}
            aria-labelledby="offset-slider"
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            valueLabelFormat={valueLabelFormat}
            step={1}
            marks={marks}
            min={0}
            max={3}
          />
        </SliderContainer>
      </Form>
      {customSelected && (
        <>
        <StyledTypography>{maxMessage}</StyledTypography>
        <CustomInputContainer>
          <FlexRow>
            <StyledTextField
              placeholder={unit === 'kW' ? 'kW' : 'Offset'}
              variant="outlined"
              value={customValue}
              onChange={handleCustomChange}
            />
            <StyledToggleButtonGroup
              value={unit}
              exclusive
              onChange={handleUnitChange}
              aria-label="unit"
            >
              <ToggleButton value="kW" aria-label="kW">
                kW
              </ToggleButton>
              <ToggleButton value="offset" aria-label="offset">
                Offset
              </ToggleButton>
            </StyledToggleButtonGroup>
            <NextButton onClick={handleConfirmCustomValue} $small>
              Set
            </NextButton>
          </FlexRow>
        </CustomInputContainer>
        </>
      )}
    </ComponentWrapper>
  );
};

export default SolarProductionOffsetSlider;


import React from 'react';
import styled, { keyframes } from 'styled-components';
import { IconButton, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Lottie from 'lottie-react';
import solarLoadingAnimation from '../../images/lottie/solar_loading.json';

const wave = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Solar-themed gradient
const gradient = `linear-gradient(
  -45deg,
  #ffeb3b, /* Solar Yellow */
  #ffa726, /* Solar Orange */
  #03a9f4, /* Solar Blue */
  #f8f4f4  /* Light White */
)`;

// Gradient variation for loading state (slower, more muted colors)
const loadingGradient = `linear-gradient(
  -45deg,
  #f9d423, /* Lighter Solar Yellow */
  #ff4e50, /* Soft Orange */
  #2196f3, /* Soft Blue */
  #f3f3f3  /* Light White */
)`;

// Gradient variation for completed state (more intense, faster)
const completedGradient = `linear-gradient(
  -45deg,
  #ffeb3b, /* Intense Solar Yellow */
  #ff5722, /* Bright Solar Orange */
  #0288d1, /* Bright Solar Blue */
  #ffffff  /* Bright White */
)`;

const BarContainer = styled.div`
  position: absolute;
  left: 50%;
  /* transform: translateX(-87%); */
  bottom: 0;
  left: 0;
  right: 0;
  width: 600px;
  height: 100px;
  background: ${({ designlocked, designstatus }) =>
    designlocked
      ? designstatus === 'completed'
        ? completedGradient
        : loadingGradient
      : gradient};
  background-size: 300% 300%;
  animation: ${wave} ${({ designlocked, designstatus }) =>
    designlocked
      ? designstatus === 'completed'
        ? '5s'
        : '12s'
      : '8s'} ease infinite;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin: 0 auto;
  z-index: 100;

  @media (max-width: 900px) {
    left: 0;
    transform: translateX(0);
    height: 80px;
    width: 100%;
    border-radius: 0;
    position: fixed;
  }
`;

const GenerateWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const StyledTypography = styled(Typography)`
  font-size: 1.5rem !important;
  color: black;
  /* color: ${({button}) => (button ? '#f8f4f4' : '#000')}; */
  font-weight: bold !important;
  margin: 15px 0;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.7), 
               0 0 16px rgba(255, 235, 59, 0.6),  /* Yellow glow */
               0 0 24px rgba(255, 165, 0, 0.6);

  @media screen and (max-width: 900px) {
    font-size: 1rem !important;
  }
`;

const LoadingContainer = styled.div`
  width: 90%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: ${({bottombar}) => (bottombar ? '0' : '20px')};
  margin: 20px;
`;

const TopContainer = styled.div`
  width: 90%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({$bottombar}) => ($bottombar ? '0' : '20px')};
  margin: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

`;

const Value = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  white-space: nowrap;

  @media screen and (max-width: 900px) {
    font-size: 1rem;
    }

    @media screen and (max-width: 500px) {
    font-size: 0.9rem;
    
    }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #555;
  max-width: 140px;
  text-align: center;
  flex-wrap: nowrap;

  @media screen and (max-width: 900px) {
    font-size: 10px;
    }
`;

const BottomBar = ({ systemSize, hardware, onClick, formState, responseState, updateHardware, designLocked, setDesignLocked, designStatus, startAutomation }) => {

  const handleStartGeneration = () => {
    setDesignLocked(true);
    startAutomation();
    onClick();
  };


  return (
    <BarContainer
      onClick={onClick}
      designlocked={designLocked}
      designstatus={designStatus}
    >
      {designStatus !== 'completed' && designStatus !== 'errored' && !designLocked && (
          <GenerateWrapper
            onClick={handleStartGeneration}
          >
            <StyledTypography>Start Generating Design</StyledTypography>
          </GenerateWrapper>
      )}

      {designLocked && designStatus !== 'completed' && designStatus !== 'errored' &&designStatus !== 'failedFinal' && (
        <LoadingContainer>
            <Lottie animationData={solarLoadingAnimation} style={{ width: 90, height: 90 }} />
          <StyledTypography variant="body1">{hardware.designText}</StyledTypography>
        </LoadingContainer>
      )}
      {designLocked && designStatus === 'failedFinal' && (
        <LoadingContainer>
            {/* <Lottie animationData={solarLoadingAnimation} style={{ width: 90, height: 90 }} /> */}
          <StyledTypography variant="body1">Design Failed</StyledTypography>
        </LoadingContainer>
      )}

      {(designStatus === 'completed' || designStatus === 'errored') && (
        <>
          {/* <IconButton>
            <ExpandLess />
          </IconButton> */}
          <TopContainer $bottombar>
            <Column>
              <Value>{(hardware.systemSize/1000).toFixed(2)} kW</Value>
              <Title>System Size</Title>
            </Column>
            {hardware.paymentType === 'Cash' &&
              <>
                <Column>
                  <Value>${Math.round(Number(hardware.totalCost)).toLocaleString()}
                  </Value>
                  <Title>Total Amount</Title>
                </Column>
                <Column>
                  <Value>${Math.round(Number(hardware.totalAfterTaxCredit)).toLocaleString()}
                  </Value>
                  <Title>Price After Incentive</Title>
                </Column>
              </>
            }
            {hardware.paymentType === 'Finance' &&
              <>
                <Column>
                  <Value>${hardware.financedMonthlyPaymentPreTaxCredit.toFixed(2).toLocaleString()}</Value>
                  <Title>Monthly Payment Pre-Incentive</Title>
                </Column>
                <Column>
                  <Value>${hardware.financedMonthlyPaymentAfterTaxCredit.toFixed(2).toLocaleString()}*</Value>
                  <Title>Monthly Payment After Incentive</Title>
                </Column>
              </>
            }
            {hardware.paymentType === '' &&
              <>
                <Column>
                  <Value>${Math.round(Number(hardware.totalAfterTaxCredit)).toLocaleString()}*
                  </Value>
                  <Title>Price After Incentives</Title>
                </Column>
                <Column>
                  <Value>${hardware.financedMonthlyPaymentAfterTaxCredit.toFixed(2).toLocaleString()}*</Value>
                  <Title>Monthly Payment</Title>
                </Column>
              </>
            }
          </TopContainer>
        </>
      )}
    </BarContainer>
  );
};

export default BottomBar;

import styled from 'styled-components';
import Autocomplete from 'react-google-autocomplete';
import background from '../../images/LunexPower.webp';
import { IoMdLocate } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { FiPlus } from 'react-icons/fi';
import { FiMinus } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { motion } from 'framer-motion';

export const QuoteOuterContainer = styled.div`
    font-family: 'Avenir_Book';
`;


export const TopBar = styled.div`
    height: 70px;
    width: 100%;
    background-color: #fff;
    position: fixed;
    z-index: 5;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
        height: 50px;
    }
`;

export const LunexHeaderLogo = styled.img`
    height: 60px;
    width: auto;
    /* margin-top: 5px; */
    margin-left: 10px;
    @media screen and (max-width: 900px) {
        height: ${({customer}) => (customer ? `60px` : `30px`)};
        margin-top: 10px;
    }
`

export const ProfileWrapper = styled.div`
    position: absolute;
    right: 0;
`

export const NavbarLogout = styled.div`
    color: #1BA8F1;
    position: absolute;
    bottom: 28px;
    right: 60px;

    @media screen and (max-width: 500px) {
        bottom: 19px;
        right: 40px;
    }

    &:hover {
        color: #ffa500;
    }
`

export const LoginLink = styled.div`
    font-size:25px;
    margin: 20px;
    color: #1BA8F1;
    text-decoration: none;
    @media screen and (max-width: 500px) {
        font-size:15px;
        margin: 15px;
    }

    &:hover {
        color: #ffa500;
    }
`

export const ProfileIcon = styled(CgProfile)`
    font-size:37px;
    margin: 15px;
    color: #1BA8F1;
    @media screen and (max-width: 500px) {
        font-size:25px;
        margin: 12px;
    }

    &:hover {
        color: #ffa500;
    }

`
export const QuoteInnerContainer = styled.div`
  width: 100%;
  height: 100vh; // Full viewport height to make it fixed
  display: flex;
  /* align-items: center;
  justify-content: center; */
  margin: 0 auto;
  /* padding-top: 200px; */

  @media screen and (max-width: 900px) {
    height: auto;
  }
`;

export const QuoteGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  gap: 20px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 0;
  }
`;

export const AnimationContainer = styled.div`
    display: flex;
    align-items: center;
`

export const ScrollAnimationWrapper = styled.div`
  height: calc(100% - 80px);
  width: auto;
  margin: 0 auto;
  margin-left: 10px;
  margin-top: 70px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);

  @media screen and (max-width: 900px) {
    display: ${({$design}) => ($design ? `none` : `block`)};
    /* height: 300px; */
    width: 95%;
    height: auto;
    margin: 0 auto;
    margin-top: 70px;
    max-height: 320px;
    max-width: 380px;
    /* overflow: auto; */
  }

  @media screen and (min-width: 2285px) {
    margin: 0 auto;
    margin-top: 70px;
  }
`;

export const QuoteBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({design}) => (design ? `calc(100% - 180px)` : `calc(100% - 80px)`)}; 
  width: auto;
  margin-left: 20px;
  padding-top: 70px;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    height: auto;
    width: 95%;
    padding-top: 60px;
    margin: 0 auto;
    max-width: 440px;
  }
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: ${({ backgroundImage }) => backgroundImage ? `url(${backgroundImage})` : `url(${background})`};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;

  @media screen and (max-width: 900px) {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const QuoteInnerWrapper = styled.div`
  width: 380px;
  max-height: 100vh;
  overflow-y: auto;
  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;
  max-width: 380px;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  border-radius: 20px;
  background-color: #f8f4f4;
  filter: ${({ ispopupopen }) => (ispopupopen ? 'blur(5px)' : 'none')};
  opacity: ${({ ispopupopen }) => (ispopupopen ? '0.6' : '1')};
  pointer-events: ${({ ispopupopen, disabled }) => (ispopupopen || disabled ? 'none' : 'auto')};
  transition: filter 0.3s ease, opacity 0.3s ease;

  display: ${({ ispopupopen }) => (ispopupopen ? 'none' : 'flex')};



  @media screen and (max-width: 900px) {
    width: 95%;
    height: auto;
    max-height: ${({design}) => (design ? `calc(100vh - 100px) !important` : '100%')};
    padding-right: 5px;
    padding-left: 5px;
    /* padding: 10px; */
    /* padding-right: 10px; */
    order: 2;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 50px;
    /* margin-bottom: 80px; */
    /* padding-bottom: 20px; */
  }
`;

export const CustomerInnerWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  max-width: 360px;
  margin: 0 auto;
  margin-right: 35px;
  margin-top: 80px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 35px;
  position: relative;
  overflow-y: auto;

  @media screen and (max-width: 900px) {
    width: 90%;
    max-height: 100%;
    height: auto;
    padding-right: 10px;
    order: 2;
    margin: 0 auto;
  }
`;

export const QuoteBgMobile = styled.div`
    display: none;
    @media screen and (max-width: 900px) {
        display: flex;
        transition: all 0.2s ease-in-out;
    }
`;

export const ImageBgMobile = styled.img`
    @media screen and (max-width: 900px) {
        width:100%;
        height: 200px;
        object-fit: cover;
    }
    
`;

export const QuoteOuterWrapper = styled.div`
    

`;

export const QuoteBackgroundWrapper = styled.div`
    
`;


export const QuoteHeader = styled.h1`
    text-align: center;
    font-size: 1.8rem;
    color: #333;
`;

export const QuoteHeader2 = styled.h2`
    margin-top: 50px;
    text-align: center;
    color: #333;
    font-size: 1.5rem;
    font-weight: 600;
`;

export const QuoteHeader3 = styled.h5`
    text-align: center;
    color: #444;
    margin-top: -15px;
    font-weight: 400;
`;

export const QuoteAutocomplete = styled(Autocomplete)`
    background-color: #E8E8E8;
    box-sizing: border-box;
    border: 2px solid #E8E8E8;
    transition: border-color 0.3s;
    border-radius: 5px;
    width: 100%;
    height: 42px;
    font-size: 13px;
    padding-left: 10px;
    font-weight: bold;
    padding-right: 25px;
    text-overflow: ellipsis;

    &:focus {
        border-color: #aaa;
        outline: none;
  }
`;

export const QuoteInputTitle = styled.label`
    font-size: 0.9rem;
    font-weight: 500;
    color: #333;
    display: block;
    text-align: left;
    margin-bottom: 4px;
`;

export const QuoteInputTitleCheckbox = styled.label`
    font-size: 1.5rem;
    font-weight: 100;
    color: #000;
    display: block;
    text-align: center;
    @media screen and (max-width: 480px) {
        font-size: 1.3rem;
    }

`;

export const StorageTitle = styled.label`
    font-size: 1.8rem;
    font-weight: 700;
    color: #000;
    display: block;
    text-align: center;
    padding: 20px;
`;

export const QuoteTextTitle = styled.label`
    font-size: 1.5rem;
    font-weight: 500;
    color: #000;
    display: block;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
`;

export const QuoteTextSubtitle = styled.label`
    font-size: 0.9rem;
    font-weight: 100;
    color: #000;
    display: block;
    text-align: center;
`;

export const QuoteLeaseNumber = styled.span`
    font-size: 2.5rem;
    font-weight: bold;
    position: relative;
    bottom: -5px;
    color: #1BA8F1; /* This is a bright blue color, but you can adjust to any other blue you prefer */
`;

export const QuoteLink = styled.a`
    font-size: 1rem;
    font-weight: 200;
    color: #000;
    display: block;
    text-align: center;
`;

export const QuoteTextTotalWrapper = styled.div`
    margin: auto;
    width:80%;
    display: flex;
    flex-direction: row;
`

export const QuoteDropdownTotalWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    width:85%;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: center;
`

export const QuoteTextTotalFirst = styled.div`
    font-size: 1.3rem;
    text-align: left;
    margin-right: 0;
    margin-top: auto;
    margin-bottom: auto;

`

export const QuoteTextTotalLast = styled.div`
    font-size: 1.3rem;
    margin-left: auto;
    margin-right: 0;
`

export const QuoteTextTotalFirstItalic = styled.div`
    font-size: 1.3rem;
    text-align: left;
    margin-right: 0;
    margin-top: auto;
    margin-bottom: auto;
    font-style: italic;

`

export const QuoteTextTotalLastItalic = styled.div`
    font-size: 1.3rem;
    margin-left: auto;
    margin-right: 0;
    font-style: italic;
`

export const QuoteTotalLeaseNumber = styled.span`
    font-size: 2.5rem;
    font-weight: bold;
    color: #1BA8F1;
`

export const DownloadLink = styled.a`
    font-size: 1rem;
    font-weight: 200;
    color: #000;
    display: block;
    text-align: center;
    text-decoration-line: underline;
    text-decoration-color: black;
    text-align: center;
`;

export const QuoteAddressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const QuoteInputWrapper = styled.div`
    width: 100%;
    margin: auto;
    display: block;
`;

export const QuoteTextWrapper = styled.div`
    width: 300px;
    margin: auto;
    @media screen and (max-width: 480px) {
        width: 100%;
    }
`;

export const QuoteRemoveWrapper = styled.div`
    width: 300px;
    background-color: ${({selected}) => (selected ? 'rgba(255,165,0,0.5)' : '#fff')};
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    padding: 15px;
    margin: auto;
    display: block;
`;

export const QuoteInput = styled.input`
    background-color: #E8E8E8;
    box-sizing: border-box;
    border: 2px solid #E8E8E8;
    transition: border-color 0.3s;
    border-radius: 5px;
    width: 100%;
    height: 42px;
    font-size: 13px;
    padding-left: 10px;
    font-weight: bold;
    padding-right: 25px;

    text-overflow: ellipsis;

    &:focus {
        border-color: #aaa;
        outline: none;
    }
`;

export const QuoteAppendedText = styled.span`
  position: absolute;
  right: 25px;
  font-size: 16px;
  font-weight: bold;
  @media screen and (max-width: 900px) {
        right: 20px;
    }
`;

export const QuoteInputCheckbox = styled.input`
    background-color: #E8E8E8;
    border-radius: 5px;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    @media screen and (max-width: 480px) {
        /* margin-bottom: 10px; */
        /* width: 97%; */
    }
`;

export const QuoteInputLocateIcon = styled(IoMdLocate)`
    height: 25px;
    position: absolute;
    right: 15px;
    /* transform: translateX(185px); */
    @media screen and (max-width: 900px) {
        right: 10px;
    }

`;

export const QuoteInputDeleteIcon = styled(IoMdClose)`
    height: 25px;
    position: absolute;
    right: 15px;
    /* transform: translateX(185px); */
    @media screen and (max-width: 900px) {
        right: 10px;
    }
`;

export const EnergyUsageColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    flex: 1;
`

export const EnergyWrapper = styled.div`
    margin: auto;
    width: 80%;
`;

export const GridWrapper = styled.div`
    margin: 10px;
`;

export const GridRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* max-width:200px; */
    width: 100%;
`;

export const GridColumn = styled.div`
    display: flex;
    background-color: ${({selected}) => (selected ? 'rgba(255,165,0,0.5)' : '#fff')};
    transition: all 0.2s ease-in-out;
    border-radius: 15px;
    max-width: 220px;

    flex-direction: column;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 10px;
`;

export const CounterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    border: 2px solid black;
`;

export const CounterMotion = styled(motion.div)`
    
`

export const CounterMinusIcon = styled(FiMinus)`
    height:28px;
    margin: 7px;
    color: ${({disabled}) => (disabled ? '#808080' : '#000')};

`;

export const CounterPlusIcon = styled(FiPlus)`
    height:28px;
    margin: 7px;
    color: ${({disabled}) => (disabled ? '#808080' : '#000')};
`;

export const CounterNumber = styled.div`
    font-size: 20px;

    margin: 6px;
    color: #000;
`;

export const SolarImages = styled.img`
    height:300px;
    width: 180px;
`

export const BatteryImages = styled.img`
    width: 180px;
    height: auto;
`;

export const TextWrapper = styled.div`
    margin: auto;
    width: 80%;
`;

export const TextCentered = styled.div`
    font-size: 1rem;
    color: grey;
    text-align: center;
`

export const IncentiveLine = styled.div`
    margin: auto;
    width: 80%;
    border-bottom: 1px solid grey;
`;

export const IncentiveText = styled.div`
    font-size: 1rem;
    color: grey;
`

export const CaptchaContainer = styled.div`
    margin: auto;
    align-items: center;
    width: 300px;
    padding-bottom:5px;
`;

export const ButtonContainer = styled.div`
    margin: auto;
    display: flex;
    flex-direction: row;
    /* display: block; */
    align-items: center;
    width: 100%;
`;

export const LoadingWrapper = styled.div`
    height: 200px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

export const CreditCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 150px;
`

export const NextButton = styled.button`
    border-radius: 5px;
    /* width: 200px; */
    width: ${({$small}) => ($small ? '70px' : '100%')};
    /* width: ${props => props.loading ? '230px' : 'auto'}; */
    height: ${props => props.loading ? '50px' : 'auto'};
    border: 2px solid white;
    background: #1BA8F1;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Avenir_Book';
    margin-left: auto;
    margin-right: auto;
    &:disabled {
        background: #808080;
        pointer-events: none;
    }
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffa500;
        color: #fff;
    }
`;

export const RemoveButton = styled.button`
    border-radius: 50px;
    border: 2px solid white;
    background: #ff0000;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Avenir_Book';
    margin-left: auto;
    margin-right: auto; 
    &:disabled {
        background: #808080;
        pointer-events: none;
    }
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffa500;
        color: #fff;
    }
`;

export const RegistrationErrorWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: auto;
    display: block;

    @media screen and (max-width: 480px) {
        width: 100%;
    }

`

export const ErrorWrapper = styled.div`
    width: 100%;
    margin: auto;
    display: block;
`

export const ErrorText = styled.div`
    color: #c9444d;
    font-size: 0.75rem;
    margin-top: 0.25rem;
`

export const RemeberMeWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
`

export const RememberMeText = styled.div`
    color: #000;
    font-size: 0.75rem;
    margin-top: 0.25rem;
`

export const RememberMeCheckbox = styled.input`
    margin-top: 8px;
`

export const AddressUrl = styled.div`
    position: relative;
    color: #555; /* A darker grey for better contrast */
    text-decoration-line: underline;
    text-decoration-color: #555; /* Match the color of the text */
    /* background-color: #f8f4f4; */
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 12px;
    font-size: 0.9rem; 
    /* max-width: 100%; */
    width: auto;
    transition: color 0.3s ease, text-decoration-color 0.3s ease; /* Smooth transition for hover effects */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

    &:hover {
        color: #ffa500;
        text-decoration-color: #ffa500;
    }
`;



export const ProductListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  /* padding: 20px; */
  margin: 0 auto;
  margin-bottom: 20px;
  /* max-width: 100%; */
`;

export const BackButton = styled.div`
    /* margin-top: 50px; */
  display: inline-flex;
  align-items: center;
  /* padding: 10px 20px; */
  background-color: #f8f4f4;
  color: #333;
  /* border-radius: 4px; */
  cursor: pointer;
  padding-bottom: 5px;
  /* &:hover {
    background-color: #0056b3;
  } */
`;

export const Arrow = styled.span`
  margin-right: 8px;
`;